@import "../mainVariables";
@import "../mixins";


.newsModal{
  .ant-modal-content{
    .ant-modal-close{
      width: 40px;
      height: 40px;
      position: absolute;
      right: -40px;
      top: -18px;
      z-index: 999;
      .ant-modal-close-x{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.5s;
        svg{
          path{
            stroke: white;
          }
        }
      }
      &:hover{
        .ant-modal-close-x{
          //background-color: rgba(255, 255, 255, 0.4) !important;
          cursor: pointer;
          svg{
            path{
              stroke: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
    }
    @media screen and (max-width: 1050px){
      .ant-modal-close{
        right: 2px;
        left: unset;
        top: -6px;
        .ant-modal-close-x{
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.5s;
          svg{
            path{
              stroke: black!important;
            }
          }
        }
      }
    }
  }
}
.news-modal-wrapper{
  width: 100%;
  .news-block-img{
    aspect-ratio: 570 / 360;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    img{
      border-radius: 14px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .news-block-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    .news-date{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #3AA9C8;
    }
    .news-block-title{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      text-align: center;
      color: #101828;
      margin-top: 12px;
    }
    .news-block-description{
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;
      color: #475467;
      margin-top: 8px;
    }
  }
}