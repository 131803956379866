@import "../mainVariables";
@import "../mixins";

.blog-wrapper {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  padding: 0 $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .news-tabs-block{
    width: 1140px;

    .subscribe-wrapper{
      padding: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .subtitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color:$brandColor;
      }
      .title{
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.01em;
        text-align: center;
        margin-top: 16px;
      }
      .description{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 20px;
        color: #475467;
      }
    }
    .last-news-wrapper{
      width: 100%;
      margin-top: 80px;
      .last-news-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        margin: 30px 0;
        color: #475467;
      }
      .last-news{
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .first-news, .other-news{
          width: 48%;
          .last-news-img{
            position: relative;
            aspect-ratio: 350 / 220;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .last-news-info{
            width: 100%;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            .date{
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color:$brandColor;
            }
            .title-wrapper{
              width: 100%;
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              margin-top: 12px;
              .title{
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0;
                text-align: left;
                width: 96%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .share{
                cursor: pointer;
              }
            }
            .description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              color: #667085;
              margin-top: 12px;
            }
          }
          &:hover{
            .last-news-info{
              .title-wrapper{
                .title{
                  color:$brandColor;
                }
                .share{
                  svg{
                    path{
                      stroke:$brandColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .news-wrapper{
      margin-top: 180px;
      padding-bottom: 60px;
      margin-bottom: 20px;
      .news-wrapper-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
      .news{
        display: flex;
        flex-wrap: wrap;
        .news-block{
          margin-right: 32px;
          &:hover{
            .news-block-info{
              .news-title-wrapper{
                .news-block-title{
                  color:$brandColor;
                }
                .news-block-link{
                  svg{
                    path{
                      stroke:$brandColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .pagination{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 20px;
      &.hide-prev{
        .ant-pagination-prev{
          display: none;
        }
      }
      &.hide-prev{
        .ant-pagination-next{
          display: none;
        }
      }
      .prev_btn{
        height: 40px;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
        }
        svg{
          margin-right: 12px;
        }
      }
      .next_btn{
        height: 40px;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        span{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
        }
        svg{
          margin-left: 12px;
        }
      }
      .ant-pagination-item{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        a{
          color: #475467;
        }
      }
      .ant-pagination-item-active{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F7FAFF;
        border-radius: 8px;
        a{
          color: #1D2939;
        }
      }
    }
  }

  @media screen and (max-width: 1200px){
    padding: 0 16px;
    .news-tabs-block{
      width: 100%;
      .subscribe-wrapper{
        padding: unset;
        margin-top: 40px;
        .title{
          font-size: 36px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: -0.01em;
          text-align: center;
        }
        .description{
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: center;
        }
      }
      .last-news-wrapper{
        margin-top: 56px;
        .last-news-title{
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
        }
        .last-news{
          flex-direction: column;
          .first-news{
            width: 100%;
            .last-news-img{
              position: relative;
              aspect-ratio: 343 / 200;
            }
          }
          .other-news{
            margin-top: 32px;
            width: 100%;
            .last-news-img{
              position: relative;
              aspect-ratio: 343 / 200;
            }
          }
        }
      }
      .news-wrapper{
        margin-top: 40px;
        .news-wrapper-title{
          font-size: 20px;
        }
      }
      .pagination{
        .prev_btn{
          border: 1px solid #D0D5DD;
          border-radius: 8px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            margin-right: unset;
          }
          span{
            display: none;
          }
        }
        .next_btn{
          border: 1px solid #D0D5DD;
          border-radius: 8px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            margin-left: unset;
          }
          span{
            display: none;
          }
        }
        .ant-pagination-simple-pager{
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 14px;
          margin-right: unset;
          input{
            margin-right: unset;
            padding: unset;
            border: unset;
          }
        }
      }
    }
  }
}